import richText from "utilities/richText"
import Card from "../atoms/Card"
import DoubleHeading from "components/DoubleHeading"
import Image from "components/Image"
import getImageDimensions from "utilities/getImageDimensions"

export default function ThreeColumnCardSection({ blok }) {
  const headingImage = blok.heading_image
  return (
    <section className="w-full py-12 lg:py-28 three-column-card">
      <div className="container flex justify-center max-w-screen-xl 2xl:max-w-screen-2xl m-auto">
        <div className="text-center max-w-2xl 2xl:max-w-3xl w-full">
          {headingImage?.filename && (
            <div className="flex justify-center">
              <div className="w-full mb-6 relative z-20">
                <Image
                  className="max-h-full max-w-screen-xs 2xl:max-w-screen-sm h-auto w-full mx-auto"
                  height={getImageDimensions("height", headingImage.filename)}
                  width={getImageDimensions("width", headingImage.filename)}
                  src={headingImage.filename}
                  alt={headingImage.alt}
                />
              </div>
            </div>
          )}
          {blok.eyebrow && (
            <span className={headingImage?.filename ? `sr-only` : `eyebrow eyebrow-2`}>{blok.eyebrow}</span>
          )}
          <DoubleHeading
            className={headingImage?.filename ? `sr-only` : `px-12 font-semibold mb-1`}
            heading={blok.heading}
            heading2={blok.heading2}
          />

          {richText(blok.content)}
        </div>
      </div>

      {blok.cards && (
        <div className="flex justify-center w-full">
          <div className="container max-w-screen-xl 2xl:max-w-screen-2xl m-auto pt-12">
            <div className="flex flex-wrap justify-center -m-3">
              {blok.cards.map((card) => {
                return (
                  <div className="w-full md:w-1/3 p-3 flex" key={`card_${blok._uid}_${card._uid}`}>
                    <div className="flex flex-col h-full w-full">
                      <Card blok={card} />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </section>
  )
}
